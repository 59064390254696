<template>
  <div class="mb-1 mx-1 pt-5 pa-3 selected">
    <div>
      <v-row>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="localEvent.plateNumber"
            hide-details
            dense
            label="Plate Number"
            :disabled="loading"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-select
            v-model="localEvent.votedVehicleType"
            :items="vehicleTypeIds"
            label="Vehicle Type"
            hide-details
            dense
            :disabled="loading"
            attach
          ></v-select>
        </v-col>
        <v-col cols="12" sm="4">
          <v-select
            v-model="localEvent.direction"
            hide-details
            dense
            :items="[
              { text: 'In', value: 'forward' },
              { text: 'Out', value: 'reverse' },
            ]"
            label="Direction"
            :disabled="loading"
            attach
          ></v-select>
        </v-col>
        <v-col cols="12">
          <div class="w-100">
            <v-btn
              class="float-right"
              small
              color="primary"
              :disabled="!isModified || loading"
              @click="saveEvent"
            >
              <template v-if="loading">
                <v-progress-circular
                  class="mr-2"
                  :size="15"
                  :width="2"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                Saving...
              </template>
              <template v-else> Save </template>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { GateReportVehicleType } from "@evercam/shared/types"

export default {
  props: {
    event: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localEvent: structuredClone(this.event),
      vehicleTypeIds: Object.values(GateReportVehicleType),
    }
  },
  computed: {
    isModified() {
      return JSON.stringify(this.localEvent) !== JSON.stringify(this.event)
    },
  },
  watch: {
    event: {
      handler(newEvent) {
        this.localEvent = structuredClone(newEvent)
      },
      deep: true,
    },
  },
  methods: {
    saveEvent() {
      this.localEvent[
        this.localEvent.contextCamera ? "contextVehicleType" : "vehicleType"
      ] = this.localEvent.votedVehicleType

      this.$emit("save-event", this.localEvent)
    },
  },
}
</script>

<style scoped>
.selected {
  border-radius: 5px;
  background: rgb(216, 237, 255);
  border: 2px solid #1b77d2;
}
.float-right {
  float: right;
}
</style>
